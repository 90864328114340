import React from "react";
import linkedin from "../components/images/linkedin.svg";

const Footer = () => {
  const handlePrivacyPolicy = () => {
    // window.location.href='https://skillshelf.cubettech.in/privacypolicy';
    window.location.href = "https://scaleuphq.io/privacypolicy";
    // window.open('http://localhost:3000/privacypolicy', '_blank');
  };
  const handleCookiePolicy = () => {
    // window.location.href='https://skillshelf.cubettech.in/cookiepolicy';
    window.location.href = "https://scaleuphq.io/cookiepolicy";
  };
  const handleFAQs = () => {
    // window.location.href='https://skillshelf.cubettech.in/faqs';
    window.location.href = "https://scaleuphq.io/faqs";
  };
  const d = new Date();
  let year = d.getFullYear();
  return (
    <div>
      <footer className="footer-sc">
        {/* <div className="col-lg-12"> */}
        <div className="container">
          <div
            class="col-12"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems:'center'
            }}
          >
            <div class="col-lg-6 col-md-12 col-sm-12 ">
              <div class="policy-line text-white" onClick={handlePrivacyPolicy}>
                Privacy Policy
              </div>
              <div class="policy-line text-white" onClick={handleCookiePolicy}>
                Cookie Policy
              </div>
              <div class="policy-line text-white" onClick={handleFAQs}>
                FAQs
              </div>
              <a href='https://www.linkedin.com/company/scaleup-hq' target="_blank" style={{paddingLeft:'16px'}}>
                <img src={linkedin} width={17} height={17} />
              </a>
            </div>
            {/* <div className="social-media">
                <ul>
                  <li>
                    <a href="#">
                      <img src={facebook} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={linkedin} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={twitter} alt="" />
                    </a>
                  </li>
                </ul>
              </div> */}
            <div class="col-lg-6 col-md-12 col-sm-12">
              <p class="my-1 text-white footer-line">
                © {year} ScaleUpHQ. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
